
import { mdiCheckCircle } from "@mdi/js"
import Button from "@/components/base/Button"
import rules from "@/mixins/rules"
import { mdiPlusCircleOutline, mdiCircleOutline } from "@mdi/js"
import { mapGetters } from "vuex"
import CopyToClipboardText from "@/components/base/input/CopyToClipboardText"
import CheckMarkAnimation from "@/components/base/CheckmarkAnimation"

export default {
    name: "BusinessInvitationModal",
    components: { CopyToClipboardText, Button, CheckMarkAnimation },
    mixins: [rules],
    data() {
        return {
            mdiCircleOutline,
            mdiCheckCircle,
            mdiPlusCircleOutline,
            editTextIndex: null,
            businessMails: [],
            currentMail: "",
            showSuccessAnimation: false,
            animationCount: 0,
            emailInputError: "",
            emailChangeError: "",
        }
    },
    computed: {
        ...mapGetters("school", ["channel", "slug"]),
        inviteURL() {
            if (this.channel && this.channel.slug) {
                return (
                    this.$config.baseURL +
                    "/arbeitgeber/einladung?channel=" +
                    this.channel.slug
                )
            } else {
                return this.$config.baseURL + "/arbeitgeber/einladung"
            }
        },
    },
    methods: {
        addEmail() {
            if (!this.currentMail) return
            if (this.businessMails.includes(this.currentMail)) {
                this.emailInputError = "E-Mail bereits vorhanden"
                return
            }
            if (this.ruleEmail(this.currentMail) === true) {
                this.businessMails.push(this.currentMail)
                this.currentMail = ""
            } else {
                this.emailInputError = this.ruleEmail(this.currentMail)
            }
        },
        removeEmail(index) {
            this.businessMails.splice(index, 1)
        },
        sendInvitations() {
            this.$axios
                .post("/api/schools/" + this.slug + "/invite_business_site", {
                    emails: this.businessMails,
                })
                .then((response) => {
                    this.showSuccessAnimation = true

                    setTimeout(() => {
                        this.showSuccessAnimation = false
                        this.businessMails = []
                        this.$emit("close")
                    }, 1500)
                    return response.data.data
                })
                .catch((error) => {
                    this.validationData = error.response.data
                })
        },
        exitEditText() {
            const editingEmail = this.businessMails[this.editTextIndex]
            if (!editingEmail) return
            if (this.ruleEmail(editingEmail) === true) {
                this.editTextIndex = null
            } else {
                this.emailChangeError = this.ruleEmail(editingEmail)
            }
        },
    },
}
